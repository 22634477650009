@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.privacy-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $background-color;

  .privacy-header-container {
    background-color: rgba(255, 255, 255, 0.8);
    height: 90px;
    padding-top: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0 0vw;
    cursor: pointer;

    .title {
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
      font-weight: 700;
      margin-right: 0.5rem;
    }

    .title-sub {
      font-weight: 100 !important;
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
    }

    .logo {
      height: 53px;
      padding-left: 2vw;

      img {
        object-fit: cover;
        height: auto;
        height: 70%;
        margin-top: 6px;
      }
    }

    .country-name {
      font-size: 0.65rem;
      color: $logo-text-color;
      margin-top: -5px;
      font-family: 'MuseoSans';
    }
  }
  .privacy-body-container {
    text-align: left;
    justify-content: left;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 60px;

    .privacy-body {
      color: $title-text-color;
      padding: 20px 0px 20px 0px;
    }
    p {
      margin: 30px;
    }
    table {
      text-align: left;
      width: auto;
      padding-top: 30px;
    }
    .table-row3 {
      text-align: center;
    }
    .table-row1 {
      padding: 20px;
    }
    .privacy-body-contact {
      font-weight: 700;
      color: $title-text-color;
      text-align: left;
      padding: 20px 0px 20px 0px;
    }
    .privacy-sub {
      text-align: center;
      justify-content: center;
      padding-bottom: 30px;
      font-weight: 700;
      color: $title-text-color;
    }
    .privacytitle {
      font-size: 2rem;
      font-weight: 700;
      font-family: $primary-font-family;
      color: $title-text-color;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      padding: 50px 50px 10px 50px;

      @media (max-width: $lg-size) {
        font-size: 2rem;
        margin-top: 1rem;
        color: $title-text-color;
        line-height: 1.5em;
      }
    }
    .privacy-subtitle {
      display: flex;
      text-align: left;
      justify-content: left;
      background-color: rgba(255, 255, 255, 0.8);
      color: $title-text-color;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 700;
      padding-left: 0px;
    }
    .privacy-subtitle-summary {
      display: flex;
      text-align: left;
      justify-content: left;
      background-color: rgba(255, 255, 255, 0.8);
      color: $title-text-color;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 700;
      padding-left: 0px;
    }
    .privacy-subline {
      padding-top: 20px;
      padding-left: 0px;
      font-weight: 700;
      color: $title-text-color;
      font-size: 16px;
    }

    .privacy-card-subtitle {
      text-decoration: underline;
      color: $title-text-color;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 20px;
    }
    .privacy-card-subtitle-text {
      margin: 10px 60px 10px 60px;
    }
    .privacy-card-container {
      text-align: left;
      margin: 30px 60px 30px 60px;
      padding: 30px;
      border-style: solid;
      border-color: $common-form-input-border;
      border-width: 1px;
      table {
        color: $title-text-color;
        align-items: center;

        td {
          padding-right: 20px;
        }
      }
    }
  }

  a {
    word-wrap: break-word;
  }
}
